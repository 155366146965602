import { View } from './View'
import { useDebtComparison } from 'hooks/useDebtComparison'
import { useFormatCurrency } from 'hooks/useFormatCurrency'
import { debtComparisonToDisplay } from '../utils/debtComparisonToDisplay'
import { normalize } from '../utils/normalize'
import { arrayOf, object } from 'prop-types'
import { generateContentData } from '../utils/stepContentDataProcess'
import { useContent } from 'hooks/useContent'
import { filter, propSatisfies, complement, includes, anyPass } from 'ramda'

const Container = ({ ...props }) => {
  const formatCurrency = useFormatCurrency({ showNonZeroDecimals: false })
  const debtComparison = useDebtComparison()
  const {
    content: { consumerFinancialOutcomes }
  } = useContent()

  const { title, subtitle } = consumerFinancialOutcomes

  const formatted = debtComparisonToDisplay(formatCurrency, debtComparison)
  const { gaps, percentages } = normalize(debtComparison)
  const comparisonsDisplayData = generateContentData(
    gaps,
    percentages,
    formatted
  )

  const comparisons = filter(
    propSatisfies(
      complement(
        anyPass([
          includes('legalDisclaimer'),
          includes('legalDisclaimerLabels')
        ])
      ),
      ':tags'
    ),
    consumerFinancialOutcomes.content
  )

  return (
    <View
      {...props}
      comparisons={comparisons}
      comparisonsDisplayData={comparisonsDisplayData}
      subtitle={subtitle}
      title={title}
    />
  )
}

Container.propTypes = {
  compositionOrder: arrayOf(object)
}

Container.displayName = 'StepContent.Container'
export { Container }
