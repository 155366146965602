import { useIsCoBrowse } from 'hooks/useIsCoBrowse'
import { useLead } from 'hooks/useLead'
import { useCallback, useEffect } from 'react'
import { useIsAgent } from '../hooks/useIsAgent'
import { useUpscope } from '../hooks/useUpscope'
import {
  EVENTS,
  EVENT_SESSION_END,
  CO_BROWSE_CHANGE_EVENTS,
  AGENT,
  CLIENT,
  EVENT_SESSION_START
} from '../constants'
import { useSteps } from 'hooks/useSteps'

let areEventsInitialized = false

const handleCoBrowseStateChange = ({
  event, // can only be one of CO_BROWSE_CHANGE_EVENTS
  beCoBrowse,
  setPage
}) => {
  const isInCoBrowse = event !== EVENT_SESSION_END

  beCoBrowse(isInCoBrowse)
  if (event === EVENT_SESSION_START) {
    setPage(0)
  }
}

const eventCallback = ({ beCoBrowse, event, setPage }) => () => {
  if (CO_BROWSE_CHANGE_EVENTS.includes(event)) {
    handleCoBrowseStateChange({ beCoBrowse, event, setPage })
  }
}

const Container = () => {
  const { fdrApplicantId, firstName, lastName } = useLead()
  const isAgent = useIsAgent()
  const upscope = useUpscope()
  const [isCoBrowse, beCoBrowse] = useIsCoBrowse()
  const { setPage } = useSteps()

  const initEvents = useCallback(() => {
    if (!areEventsInitialized) {
      EVENTS.forEach((event) => {
        upscope(
          'on',
          event,
          eventCallback({
            beCoBrowse,
            event,
            fdrApplicantId,
            isAgent,
            setPage
          })
        )
      })

      areEventsInitialized = true
    }
  }, [upscope, beCoBrowse, fdrApplicantId, isAgent, setPage])

  useEffect(() => {
    if (upscope) {
      upscope('updateConnection', {
        identities: [firstName, lastName, isAgent ? AGENT : CLIENT],
        uniqueId: fdrApplicantId
      })

      initEvents()
    }
  }, [fdrApplicantId, firstName, initEvents, isAgent, lastName, upscope])

  useEffect(() => {
    const coBrowseAttribute = 'upscope-co-browse'

    document
      .body
      .setAttribute(coBrowseAttribute, isCoBrowse)
  }, [isCoBrowse])

  return null
}

export { Container, handleCoBrowseStateChange, eventCallback }
