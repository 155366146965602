import { useEffect } from 'react'
import { getApplicationData } from '../../utils/getApplicationData'
import { useSteps } from 'hooks/useSteps'

const usePageChange = ({
  pathname,
  analytics,
  fdrApplicantId,
  ownerId,
  isCoBrowse,
  isCoBrowsePrev
}) => {
  const { all, current } = useSteps()

  useEffect(() => {
    // prevent triggering page change when isCoBrowse changes
    if (isCoBrowse !== isCoBrowsePrev) {
      return
    }

    const applicationData = getApplicationData(
      {
        isCoBrowse,
        ownerId,
        pageName: current?.label,
        pathname
      },
      all
    )

    analytics.page(applicationData.page_name, {
      application_data: applicationData,
      profile: { fdrApplicantId }
    })
  }, [
    pathname,
    analytics,
    fdrApplicantId,
    ownerId,
    isCoBrowse,
    isCoBrowsePrev,
    all,
    current
  ])
}

export { usePageChange }
