import { useEnv } from 'hooks/useEnv'
import { either, isEmpty, isNil } from 'ramda'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'

const isNullOrEmpty = either(isNil, isEmpty)

const Container = () => {
  const { env, isLoading } = useEnv()

  useEffect(() => {
    if (isNullOrEmpty(env) || isLoading) {
      return
    }

    const gtmId = env?.GOOGLE_TAG_MANAGER_ID

    gtmId && TagManager.initialize({ gtmId })
  }, [env, isLoading])

  return null
}

Container.displayName = 'GoogleTagManager.Container'

export { Container }
