import { EVENT_ACTION } from '@ffn/cx-event-horizon/browser'
import cx from 'classnames'
import { Image } from 'components/Image'
import { Phone, variant as phoneVariant } from 'components/Phone'
import { clickEvent } from 'consts/clickEvent'
import { qaId } from 'consts/qaId'
import { useAnalytics } from 'hooks/useAnalytics'
import { useSteps } from 'hooks/useSteps'
import { bool, func, node, string, shape } from 'prop-types'
import React from 'react'
import { qaAttributes } from 'utils/qaAttributes'
import Hamburger from './Hamburger.svg'
import HamburgerWhite from './HamburgerWhite.svg'
import styles from './index.module.scss'
export const PHONE_CLICK_EVENT_NAME = 'Header - Phone - Clicked'

const View = ({
  beMobileNavOpen,
  children,
  className,
  isDark,
  isMinimal,
  phone,
  logoUrl,
  logoWhiteUrl,
  hideMenu
}) => {
  const { current } = useSteps()
  const { track } = useAnalytics()
  const handlePhoneClick = () => {
    track(PHONE_CLICK_EVENT_NAME, {
      event_action: EVENT_ACTION.BUTTON_CLICK,
      page_name: current?.label
    })
  }
  return (
    <header
      className={cx(styles.container, { [styles.darkMode]: isDark }, className)}
    >
      <div
        className={cx(styles.inner, {
          [styles.minimal]: isMinimal,
          [styles.hideMenu]: hideMenu
        })}
      >
        {!isMinimal && (
          <>
            {!hideMenu && (
              <button
                {...qaAttributes({
                  id: `${qaId.NAV_MOBILE_PREFIX}-open-mobile-nav`
                })}
                className={styles.hamburger}
                id={clickEvent.APP_NAVIGATION_DRAWER_OPEN}
                onClick={() => beMobileNavOpen(true)}
              >
                <Image
                  alt="Open navigation"
                  height={18}
                  src={isDark ? HamburgerWhite : Hamburger}
                  width={18}
                />
              </button>
            )}
            <img
              alt="Logo"
              className={styles.logo}
              src={isDark ? logoWhiteUrl : logoUrl}
            />
            <div className={styles.content}>{children}</div>
          </>
        )}
        <div
          className={styles.phone}
          onClick={handlePhoneClick}
          onKeyDown={(event) => {
            event.stopPropagation()
          }}
          role="button"
          tabIndex="0"
        >
          <Phone
            {...qaAttributes({
              id: qaId.HEADER_PHONE_LINK
            })}
            id={clickEvent.APP_HEADER_PHONE}
            variant={isDark ? phoneVariant.WHITE : phoneVariant.BLUE}
            {...phone}
          />
        </div>
      </div>
    </header>
  )
}

View.defaultProps = {
  beMobileNavOpen: () => {}
}

View.propTypes = {
  beMobileNavOpen: func,
  children: node,
  className: string,
  hideMenu: bool,
  isDark: bool,
  isMinimal: bool,
  logoUrl: string,
  logoWhiteUrl: string,
  phone: shape({
    href: string,
    phoneNumber: string
  })
}

View.displayName = 'Header'

export { View }
