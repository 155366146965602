import { useEffect } from 'react'
import { EVENT_ACTION } from '@ffn/cx-event-horizon/browser'
import { useSteps } from 'hooks/useSteps'
import { analyticsTrackEvent } from 'consts/analyticsTrackEvent'
import { getApplicationData } from '../../utils/getApplicationData'

const useCoBrowseChange = ({
  pathname,
  analytics,
  fdrApplicantId,
  ownerId,
  isCoBrowse,
  isCoBrowsePrev
}) => {
  const { all, current } = useSteps()

  useEffect(() => {
    if (
      (!isCoBrowse && isCoBrowsePrev === undefined) || // skip initial effect
      isCoBrowse === isCoBrowsePrev // or no change
    ) {
      return
    }

    const pageName = current?.label

    const applicationData = getApplicationData(
      {
        isCoBrowse,
        ownerId,
        pageName,
        pathname
      },
      all
    )

    // Anything for track should be snake case
    analytics.track(analyticsTrackEvent.CO_BROWSE_STATE_CHANGE, {
      application_data: applicationData,
      event_action: EVENT_ACTION.APP_EVENT,
      page_name: pageName,
      profile: { fdrApplicantId }
    })
  }, [
    isCoBrowse,
    isCoBrowsePrev,
    fdrApplicantId,
    ownerId,
    pathname,
    analytics,
    all,
    current
  ])
}

export { useCoBrowseChange }
