import { useAnalytics } from 'hooks/useAnalytics'
import { useLead } from 'hooks/useLead'
import { useLocation } from 'react-router-dom'
import { useIsCoBrowse } from 'hooks/useIsCoBrowse'
import { usePrevious } from 'hooks/useIsPrevious'
import { usePageChange } from './hooks/usePageChange'
import { useCoBrowseChange } from './hooks/useCoBrowseChange'

const GlobalTrack = () => {
  const { pathname } = useLocation()
  const lead = useLead()
  const { fdrApplicantId, ownerId } = lead
  const analytics = useAnalytics()
  const [isCoBrowse] = useIsCoBrowse()
  const isCoBrowsePrev = usePrevious(isCoBrowse)
  const options = {
    analytics,
    fdrApplicantId,
    isCoBrowse,
    isCoBrowsePrev,
    ownerId,
    pathname
  }

  usePageChange(options)

  useCoBrowseChange(options)

  return null
}

export { GlobalTrack }
