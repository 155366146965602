const EVENT_SESSION_START = 'sessionStart'
const EVENT_SESSION_CONTINUE = 'sessionContinue'
const EVENT_SESSION_END = 'sessionEnd'

const CO_BROWSE_CHANGE_EVENTS = [
  EVENT_SESSION_START
]

const EVENTS = [
  'connection',
  'connectionReset',
  'sessionRequest',
  ...CO_BROWSE_CHANGE_EVENTS,
  'waitingForCall',
  'callAccepted',
  'agentRequestUpdate',
  'agentsAvailable',
  'callStart',
  'callEnd'
]

const AGENT = '(Agent)'
const CLIENT = '(Client)'

export {
  EVENT_SESSION_START,
  EVENT_SESSION_CONTINUE,
  EVENT_SESSION_END,
  CO_BROWSE_CHANGE_EVENTS,
  EVENTS,
  AGENT,
  CLIENT
}
