import { View } from './View'
import { useSteps } from 'hooks/useSteps'
import normalize from 'normalize-path'
import { shape, string } from 'prop-types'
import { transitionVariants, pageTransition } from './constants'

const Container = (props) => {
  const { currentIndex, previousIndex, all } = useSteps()
  const direction = currentIndex >= previousIndex ? 1 : -1

  const { match } = props
  const normalizedRedirect = normalize(`${match.url}/${all[0].path}`, false)

  return (
    <View
      horizontalScrollDirection={direction}
      normalizedRedirect={normalizedRedirect}
      pageTransition={pageTransition}
      routes={all}
      transitionVariants={transitionVariants}
      {...props}
    />
  )
}

Container.displayName = 'StepsLayout'

Container.propTypes = {
  match: shape({
    path: string.isRequired
  }).isRequired
}

export { Container }
