import { Suspense } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { rootRoute } from 'consts/rootRoute'
import { StepsLayout } from './StepsLayout'
import { NotFound } from 'components/NotFound'
import { AuthorizedRoute } from 'components/AuthorizedRoute'
import { RedirectWithQuery } from 'components/RedirectWithQuery'
import { ScrollToTop } from 'components/ScrollToTop'
import { Provider } from './Provider'
import { GlobalTrack } from 'components/GlobalTrack'
import { PageLoadingSpinner } from 'components/PageLoadingSpinner'
import { ContentRefetch } from 'components/ContentRefetch'
import { AgentScript } from 'components/AgentScript'
import { Feature } from 'components/Feature'
import { featureFlags } from 'shared/constants/featureFlags'
import { NavigationControl } from 'components/NavigationControl'

const View = () => (
  <Provider>
    <Suspense fallback={<PageLoadingSpinner />}>
      <Router>
        <ScrollToTop />
        <Switch>
          <RedirectWithQuery exact from="/" to={rootRoute.STEPS} />
          <Route component={NotFound} path="/404" />
          <AuthorizedRoute component={StepsLayout} path="/:deckName" />
          <Route component={NotFound} />
        </Switch>
        <ContentRefetch />
        <GlobalTrack />
        <Feature feature={featureFlags.AGENT_SCRIPT}>
          <AgentScript />
        </Feature>
        <NavigationControl />
      </Router>
    </Suspense>
  </Provider>
)

export { View }
